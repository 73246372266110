@import url('https://fonts.googleapis.com/css2?family=Cantarell&family=Poppins&family=Spectral&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}
body {

  background-color: whitesmoke;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  width: 13%;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
  transition: all .5s ease-in-out;
}
.logo:hover { transform: scale(1.1); }
.paper {
  align-items: center;
  padding: 0 20px;
  height: auto;
  width: auto;
}

.preview-text{
  padding-left: 2rem;
  color: green;
  font-weight: 700;
}
#content{
 padding: 5px;
  width:  700px;
  height: 834px;
}
.paper1 {
  align-items: center;
  height: auto;
  padding: 8px;
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.back {
  margin-top: 4rem;
  margin-bottom: -3rem;
}

.paper2 {
  align-items: center;
  padding: 6rem;
  margin: 2rem;
  height: auto;
}
.app-btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.title-sponsored {
  color: grey;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  padding-top: 2rem;
}

.title2 {
  color: grey;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 2rem;
  margin-top: 5rem;
}
.textfield {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 30px;
  margin: 10px;
}
.link {
  text-decoration: none;
  color: forestgreen;
}
.textfield > .error {
  font-size: 11px;
  font-weight: 600;
  display: block;
  color: red;
}
.steps {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: forestgreen;
  margin-top: 1rem;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  margin: 10px auto;
  
}

.success {
  color: green;
  margin: 5px auto;
  padding: 10px;
  text-align: center;
}
.app-btn2 {
  width: 250px;
  height: 10vh; 
}
.next {
  width: 210px;
  height: 7vh;
}
.next-btn{
  width: 210px;
  height: 7vh;
  padding-bottom: 4rem;
  margin: 0 auto;
}
.table-page{
  line-height: 3;
  margin: 7rem;
  object-fit: contain;
}
.image-page{
 
    border-radius: 50%;
    margin-top: 4.5rem;
    margin-bottom: -4rem;
  
}
@media (max-width: 500px) {
  .table-page{
    margin-left: -10rem;
  }
  .image-page{
    margin-left: -16rem;
    
  }
  .textfield {
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: -3rem auto;
  }
  .textfield > .box {
    margin-bottom: 30px;
    width: 100%;
  }
  .logo {
    width: 25%;
    height: 10vh;
    margin-top: 20px;
  }
  .next {
    width: 70%;
    height: 6vh;
    margin-top: 1rem;
  }
  
  .success {
    font-size: 15px;
  }
  .steps {
    margin-bottom: 40px;
  }
  .title-sponsored{
    font-size: 16px; 
  }
  
  
  .paper1 {
    align-items: center;
    padding: 40px 20px;
    height: auto;
  }
  .check {
    padding: 10px;
    margin-bottom: 40px;
    text-align: center;
  }
  .app-btn, .btn, .app-btn1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }
  .app-btn1 {
    width: 400px;
    padding: 20px;  
  }
  .app-btn2 {
    width: 85%;
    height: 7vh;
     
  }
  .paper2 {
    align-items: center;
    margin: 20px auto;
    height: auto;
    width: 100%;
  }
  .title2{
    font-size: 16px;
    margin-top: 8rem;
  }
  .label-check{
    font-size: 13px;
  }
  
}
